import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Paths } from "routes";
import TokenService from "services/token.service";

const Home = React.lazy(
  () => import(/* webpackChunkName: "pages_home" */ "pages/home")
);
const MyAccount = React.lazy(
  () => import(/* webpackChunkName: "pages_profile" */ "pages/my-account")
);
const MyTickets = React.lazy(
  () => import(/* webpackChunkName: "pages_profile" */ "pages/my-tickets")
);
const NFTTickets = React.lazy(
  () => import(/* webpackChunkName: "pages_all-events" */ "pages/nft-tickets")
);
const NFTMembership = React.lazy(
  () =>
    import(/* webpackChunkName: "pages_all-events" */ "pages/nft-membership")
);

const WhiteLabel = React.lazy(
  () => import(/* webpackChunkName: "pages_all-events" */ "pages/white-label")
);
const AboutPage = React.lazy(
  () => import(/* webpackChunkName: "pages_all-events" */ "pages/about")
);
const SearchResults = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages_search-results" */ "pages/search-results"
    )
);
const EventDetails = React.lazy(
  () =>
    import(/* webpackChunkName: "pages_event-details" */ "pages/event-details")
);
const OrderDetails = React.lazy(
  () =>
    import(/* webpackChunkName: "pages_order-details" */ "pages/order-details")
);
const HowItWorks = React.lazy(
  () =>
    import(/* webpackChunkName: "pages_how-it-works" */ "pages/how-it-works")
);
const NotFound = React.lazy(
  () => import(/* webpackChunkName: "pages_not-found" */ "pages/not-found")
);
const SignIn = React.lazy(
  () => import(/* webpackChunkName: "pages_not-found" */ "pages/sign-in")
);
const SignUp = React.lazy(
  () => import(/* webpackChunkName: "pages_not-found" */ "pages/sign-up")
);
const ForgotPassword = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages_forgot-password" */ "pages/forgot-password"
    )
);
const WalletConnect = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages_wallet-connect" */ "pages/wallet-connect"
    )
);
const PersonalInfo = React.lazy(
  () =>
    import(/* webpackChunkName: "pages_personal-info" */ "pages/personal-info")
);
const WalletInfo = React.lazy(
  () => import(/* webpackChunkName: "pages_wallet-info" */ "pages/wallet-info")
);
const BuyTicket = React.lazy(
  () => import(/* webpackChunkName: "pages_buy-ticket" */ "pages/buy-ticket")
);

const TestPayment = React.lazy(
  () =>
    import(/* webpackChunkName: "pages_test-payment" */ "pages/test-payment")
);
// const EventForm = React.lazy(
//   () => import(/* webpackChunkName: "pages_event-form" */ "pages/event-form")
// );
const MembershipAppForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages_membership-app-form" */ "pages/membership-app-form"
    )
);

const WhiteLabelForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages_membership-app-form" */ "pages/white-label-form"
    )
);

const EventAppForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages_membership-app-form" */ "pages/event-app-form"
    )
);

const TermsAndConditions = React.lazy(
  () =>
    import(
      /* webpackChunkName: "pages_terms-and-conditions" */ "pages/terms-and-conditions"
    )
);

const PrivateRoute = ({ Element }) => {
  return TokenService.getLocalAccessToken() ? (
    <Element />
  ) : (
    <Navigate to={Paths.HOME} />
  );
};

const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route path={Paths.HOME} element={<Home />} />
      <Route path={Paths.SIGN_IN} element={<SignIn />} />
      <Route path={Paths.Sign_UP} element={<SignUp />} />
      <Route path={Paths.NFT_TICKETS} element={<NFTTickets />} />
      <Route path={Paths.NFT_MEMBERSHIP} element={<NFTMembership />} />
      <Route path={Paths.WHITE_LABEL} element={<WhiteLabel />} />
      <Route path={Paths.ABOUT} element={<AboutPage />} />
      <Route path={Paths.SEARCH_RESULTS} element={<SearchResults />} />
      <Route path={Paths.EVENT_DETAILS} element={<EventDetails />} />
      <Route path={Paths.ORDER_DETAILS} element={<OrderDetails />} />
      <Route path={Paths.HOW_IT_WORKS} element={<HowItWorks />} />
      <Route path={Paths.FORGOT_PASSWORD} element={<ForgotPassword />} />

      <Route
        path={Paths.TERMS_AND_CONDITIONS}
        element={<TermsAndConditions />}
      />
      {/* <Route path={Paths.EVENT_FORM} element={<EventForm />} /> */}
      <Route path={Paths.MEMBERSHIP_APP_FORM} element={<MembershipAppForm />} />
      <Route path={Paths.EVENT_APP_FORM} element={<EventAppForm />} />
      <Route path={Paths.WHITE_LABEL_FORM} element={<WhiteLabelForm />} />

      <Route
        path={Paths.MY_ACCOUNT}
        element={<PrivateRoute Element={MyAccount} />}
      />
      <Route
        path={Paths.MY_TICKETS}
        element={<PrivateRoute Element={MyTickets} />}
      />
      <Route
        path={Paths.WALLET_CONNECT}
        element={<PrivateRoute Element={WalletConnect} />}
      />
      <Route
        path={Paths.PERSONAL_INFO}
        element={<PrivateRoute Element={PersonalInfo} />}
      />
      <Route
        path={Paths.WALLET_INFO}
        element={<PrivateRoute Element={WalletInfo} />}
      />
      <Route
        path={Paths.BUY_TICKET}
        element={<PrivateRoute Element={BuyTicket} />}
      />

      <Route path={Paths.TEST_PAYMENT} element={<TestPayment />} />

      {/* <Route path={Paths.FAVORITES} element={<PrivateRoute Element={Favorites} />} /> */}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
