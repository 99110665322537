import { createSlice } from "@reduxjs/toolkit";
import { getAllEvents, getUpcomingEvents } from "store/actions/events-actions";
import { EventsState, EventState } from "store/types/events-types";
import { getFeaturedEvents } from './../actions/events-actions';

const eventInitialState: EventState = {
  content: null,
  loading: false,
  loaded: false,
  error: false,
}

const initialState: EventsState = {
  allEvents: eventInitialState,
  upcomingEvent: eventInitialState,
  featuredEvents: eventInitialState,
};



const eventsSlice = createSlice({
  name: "events",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllEvents.pending, (state) => {
        state.allEvents.loading = true;
      })
      .addCase(getAllEvents.fulfilled, (state, { payload }) => {
        state.allEvents.content = payload;
        state.allEvents.loading = false;
      })
      .addCase(getAllEvents.rejected, (state) => {
        state.allEvents.loading = false;
        state.allEvents.error = true;
      })
      
      .addCase(getUpcomingEvents.pending, (state) => {
        state.upcomingEvent.loading = true;
      })
      .addCase(getUpcomingEvents.fulfilled, (state, { payload }) => {
        state.upcomingEvent.content = payload;
        state.upcomingEvent.loading = false;
      })
      .addCase(getUpcomingEvents.rejected, (state) => {
        state.upcomingEvent.loading = false;
        state.upcomingEvent.error = true;
      })

      .addCase(getFeaturedEvents.pending, (state) => {
        state.featuredEvents.loading = true;
      })
      .addCase(getFeaturedEvents.fulfilled, (state, { payload }) => {
        state.featuredEvents.content = payload;
        state.featuredEvents.loading = false;
      })
      .addCase(getFeaturedEvents.rejected, (state) => {
        state.featuredEvents.loading = false;
        state.featuredEvents.error = true;
      });
  },
});

export default eventsSlice.reducer;
