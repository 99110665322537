import { FC } from "react";
import { useNavigate } from "react-router-dom";
import ImageLogo from "assets/images/image-logo.png";
import { Paths } from "routes";
import "./styles.scss";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Footer: FC = () => {
  const navigate = useNavigate();



  return (
    <footer className="footer text-lexend-400-18">
      <div className="footer__content">
        <div className="footer__content-item">
          <div className="footer__logo-wrapper" onClick={goToHomePage} >
            <img className="footer__logo" src={ImageLogo} alt="logo"/>
          </div>
          <div className="footer__nav-item" onClick={goToHomePage}>
            Home
          </div>
          <div className="footer__nav-item" onClick={goToNFTTicketsPage}>
            NFT Tickets
          </div>
          <div className="footer__nav-item" onClick={goToNFTMembershipPage}>
            NFT Membership
          </div>
          <div className="footer__nav-item" onClick={goToHowItWorksPage}>
            How it works
          </div>
        </div>

        <div className="footer__content-item">
          <div className="footer__contact-details">
            <a className="footer__email" href="mailto:admin@ticketmeta.io">
              marketing@ticketmeta.io
            </a>
          </div>
        </div>
        {/* <div className="footer__content-item">
          <div className="footer__subscribe">Subscribe to our newsletter</div>
          <div className="footer__input-container">
            <Input inputClassName="footer__input" placeholder="Enter email address" />
            <Button className="footer__subscribe-btn">Subscribe</Button>
          </div>
        </div> */}
      </div>

      <div className="footer__footer">
        <div className="footer__marker">2023 TicketMeta</div>
        <div className="footer__social">
          <a href="https://twitter.com/TicketMeta_io" target="_blank">
            <span>
            <FontAwesomeIcon icon={icon({name: 'twitter', style: 'brands'})} />
            </span>
          </a>
          <a href="https://www.linkedin.com/company/ticketmeta/" target="_blank">
            <span>
            <FontAwesomeIcon icon={icon({name: 'linkedin', style: 'brands'})} />
            </span>
          </a>
          <a href="https://discord.gg/s28Eyuv5" target="_blank">
            <span>
            <FontAwesomeIcon icon={icon({name: 'discord', style: 'brands'})} />
            </span>
          </a>
          <a href="https://instagram.com/tmeta_nft?igshid=YmMyMTA2M2Y=" target="_blank">
            <span>
            <FontAwesomeIcon icon={icon({name: 'instagram', style: 'brands'})} />
            </span>
          </a>
          <a href="https://medium.com/@irinaverse" target="_blank">
            <span>
            <FontAwesomeIcon icon={icon({name: 'medium', style: 'brands'})} />
            </span>
          </a>
        </div>
        <div className="footer__terms-wrapper">
          <span className="footer__terms" onClick={goToTermsAndConditions}>
            Terms and Conditions Agreement
          </span>
        </div>
      </div>
      <div className="footer__contacts">
        <p>
          <strong>TICKETMETA FZ-LLC, RAK, UAE</strong>Compass Building, Al Shohada Road, AL Hamra Industrial Zone-FZ  N45001091
        </p>
      </div>
    </footer>
  );

  function goToTermsAndConditions() {
    navigate(Paths.TERMS_AND_CONDITIONS);
  }

  function goToHomePage() {
    navigate(Paths.HOME);
    window.scrollTo(0, 0);
  }

  function goToNFTTicketsPage() {
    navigate(Paths.NFT_TICKETS);
    window.scrollTo(0, 0);
  }

  function goToNFTMembershipPage() {
    navigate(Paths.NFT_MEMBERSHIP);
    window.scrollTo(0, 0);
  }

  function goToHowItWorksPage() {
    navigate(Paths.HOW_IT_WORKS);
    window.scrollTo(0, 0);
  }
};

export default Footer;
