import cookies from "js-cookie";

const getLocalRefreshToken = () => {
  const refreshToken = cookies.get("refresh_token");
  return refreshToken ? JSON.parse(refreshToken) : null;
};

const getLocalAccessToken = () => {
  const accessToken = cookies.get("access_token");
  return accessToken ? JSON.parse(accessToken) : null;
};

const updateLocalAccessToken = (token: string) => {
  cookies.set("access_token", JSON.stringify(token), { expires: 2 });
};

const updateLocalRefreshToken = (token: string) => {
  cookies.set("refresh_token", JSON.stringify(token), { expires: 3 });
};

const removeTokens = () => {
  cookies.remove("access_token");
  cookies.remove("refresh_token");
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  updateLocalRefreshToken,
  removeTokens,
};

export default TokenService;
