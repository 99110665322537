import { useMemo, FC, ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";
import cn from "classnames";
import { IconClose } from "assets/icons";
import "./styles.scss";


const overlay: HTMLElement = document.getElementById("overlay") as HTMLElement;

interface ModalProps {
  children: ReactNode;
  onClose: () => void;
  withCloseIcon?: boolean;
  className?: string;
  actions?: React.ReactNode[];
}

const Modal: FC<ModalProps> = ({ children, className, onClose, actions, withCloseIcon }) => {
  const element = useMemo(() => {
    const el = document.createElement("div");
    el.classList.add("overlay-item");
    return el;
  }, []);

  useEffect(() => {
    overlay.appendChild(element);
    document.body.style.overflow = 'hidden';

    return () => {
      overlay.removeChild(element);
      document.body.style.overflow = 'unset';
    };
  }, []);

  return ReactDOM.createPortal(
    <div className="modal" onClick={onClose}>
      <div className={cn("modal__content", className)} onClick={stopPropagation}>
        {withCloseIcon && <div className="modal__close-icon-wrapper" onClick={onClose}><IconClose /></div>}
        {children}
        {actions?.length  && <div className="modal__actions-container">{actions}</div>}
      </div>
    </div>,
    element
  );

  function stopPropagation(e) {
    e.stopPropagation();
  }
};

export default Modal;
