// @ts-nocheck
// @tsx-nocheck

import Footer from "components/footer";
import Header from "components/header";
import ErrorPage from "pages/error-page";
import React from "react";
const { NODE_END } = process.env;

class ErrorBoundary extends React.Component {
  state = { error: undefined, errorInfo: undefined };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    if (errorInfo) {
      if (NODE_END === "development") {
        return (
          <section className="pr__main__content">
            <header>
              <h3>An unexpected error has occurred.</h3>
            </header>

            <section>
              <details className="preserve-space">
                {error && error.toString()}
                <br />
                {errorInfo?.componentStack}
              </details>
            </section>
          </section>
        );
      }

      return (
        <>
          <Header />
          <ErrorPage />
          <Footer />
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
