import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "api";

export const getAllEvents: any = createAsyncThunk("events/get-all", async (_, { rejectWithValue }) => {
  let url = "event/list";

  try {
    const response = await API.get(url);
    const { data } = response;
    return data;
  } catch (e) {
    throw rejectWithValue(e);
  }
});

export const getUpcomingEvents: any = createAsyncThunk("events/get-upcoming", async (_, { rejectWithValue }) => {
  let url = "event/list?orderBy=Upcoming";

  try {
    const response = await API.get(url);
    const { data } = response;
    return data;
  } catch (e) {
    throw rejectWithValue(e);
  }
});

export const getFeaturedEvents: any = createAsyncThunk("events/get-featured", async (_, { rejectWithValue }) => {
  let url = "event/list?orderBy=Completed";

  try {
    const response = await API.get(url);
    const { data } = response;
    return data;
  } catch (e) {
    throw rejectWithValue(e);
  }
});
