import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "routes";
import Button from "components/button";
import ImageVirtualInterfaces from "assets/images/image-virtual-interfaces.png";
import "./styles.scss";

const ErrorPage: FC = () => {
  const navigate = useNavigate();

  return (
    <section className="error-page">
      <div className="error-page__title">Oh no! Something went wrong!</div>
      <div className="error-page__image-wrapper">
        <img src={ImageVirtualInterfaces} alt="error-page" />
      </div>
      <div>
        <Button className="primary" onClick={goHome}>
          Go to the home page
        </Button>
      </div>
    </section>
  );

  function goHome() {
    navigate(Paths.HOME);
  }
};

export default ErrorPage;
