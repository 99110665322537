import { ButtonHTMLAttributes, FC } from "react";
import cn from 'classnames';
import "./styles.scss";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    iconRight?: React.ReactNode;
};

const Button: FC<ButtonProps> = ({className, iconRight, children, ...rest}) => {

    return (
        <button className={cn("button", className)} {...rest}>
            {children}
            {iconRight && <span className="icon-right">{iconRight}</span>}
        </button>
    );
};

export default Button;
