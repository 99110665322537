import { createSlice } from "@reduxjs/toolkit";
import { getLogout, getProfile } from "store/actions/profile-actions";
import { ProfileState } from "store/types/profile-types";
import { updateProfile } from './../actions/profile-actions';

const initialState: ProfileState = {
  content: null,
  loading: false,
  loaded: false,
  error: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.content = null;
        state.loading = true;
        state.loaded = false;
        state.error = false;
      })
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        state.content = payload;
        state.loading = false;
        state.loaded = true;
        state.error = false;
      })
      .addCase(getProfile.rejected, (state) => {
        state.content = null;
        state.loading = false;
        state.loaded = false;
        state.error = true;
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        state.content = payload;
        state.loading = false;
        state.loaded = true;
        state.error = false;
      })
      .addCase(updateProfile.rejected, (state) => {
        state.content = null;
        state.loading = false;
        state.loaded = false;
        state.error = true;
      })
      .addCase(getLogout.pending, (state) => {
        state.loading = true;
        state.loaded = false;
        state.error = false;
      })
      .addCase(getLogout.fulfilled, (state) => {
        state.content = null;
        state.loading = false;
        state.loaded = false;
        state.error = false;
      });
  },
});

export default profileSlice.reducer;
