export enum Paths {
    HOME = "/",
    NFT_TICKETS = "/all-events",
    NFT_MEMBERSHIP = "/nft-membership",
    ABOUT = "/about-us",
    FAVORITES = "/favorites",
    HOW_IT_WORKS = "/how-it-works",
    EVENT_DETAILS = "/event/:eventId",
    ORDER_DETAILS = "/order-details/:eventId",
    SIGN_IN = "/sign-in",
    Sign_UP = "/sign-up",
    SEARCH_RESULTS = "/search-results",
    FORGOT_PASSWORD = "/forgot-password",
    MY_ACCOUNT = "/my-account",
    MY_TICKETS = "/my-tickets",
    WALLET_CONNECT = "/wallet-connect",
    BUY_TICKET = "/buy-ticket/:eventId",
    TERMS_AND_CONDITIONS = "/terms-and-conditions",
    MEMBERSHIP_APP_FORM = "/membership-form",
    EVENT_APP_FORM = "/event-form",
    WHITE_LABEL = "/white-label",
    WHITE_LABEL_FORM = "/white-label-form",

    TEST_PAYMENT = "/test-payment",

    //Mobile only
    PERSONAL_INFO = "/personal-info",
    WALLET_INFO = "/wallet-info",
}
