import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IconBurgerMenu,
  IconLogoutSmall,
  IconProfile,
  IconProfileSmall,
  IconTicketSmall,
} from "assets/icons";
import Button from "components/button";
import cn from "classnames";
import { Paths } from "routes";
import { useAccount, useDisconnect } from "wagmi";
import Modal from "components/modal";
import BackgroundEffects from "components/background-effects";
import TokenService from "services/token.service";
import { useDispatch, useSelector } from "react-redux";
import { getLogout } from "store/actions/profile-actions";
import useMediaQuery from "hooks/useMediaQuery";
import ButtonLoader from "components/button-loader";
import { profileSelector } from "store/selectors/profile-selector";
import ImageLogo from "assets/images/image-logo.png";
import "./styles.scss";

const Header: FC = () => {
  const [isProfilePopoverOpen, setIsProfilePopoverOpen] = useState(false);
  const [isMobileProfileMenuOpen, setIsMobileProfileMenuOpen] = useState(false);
  const [isMobileNavMenuOpen, setIsMobileNavMenuOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [search, setSearch] = useState("");

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector(profileSelector);
  const { disconnect } = useDisconnect();

  const profileIconRef = useRef<any>();
  const mobileProfileIconRef = useRef<any>();
  const mobileProfileListRef = useRef<any>();
  const mobileNavMenuIconRef = useRef<any>();
  const mobileNavMenuListRef = useRef<any>();

  const { isConnected, connector } = useAccount();

  const isHomePage = useMemo(() => pathname === Paths.HOME, [pathname]);
  const isAllEventsPage = useMemo(
    () => pathname === Paths.NFT_TICKETS,
    [pathname]
  );
  const isMembership = useMemo(
    () => pathname === Paths.NFT_MEMBERSHIP,
    [pathname]
  );
  // const isFavoritesPage = useMemo(() => pathname === Paths.FAVORITES, [pathname]);
  const isHowItWorksPage = useMemo(
    () => pathname === Paths.HOW_IT_WORKS,
    [pathname]
  );
  const isAboutPage = useMemo(() => pathname === Paths.ABOUT, [pathname]);

  const accessToken = TokenService.getLocalAccessToken();

  const isTabletLandscape = useMediaQuery("(min-width: 996px)");

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isProfilePopoverOpen &&
        profileIconRef.current &&
        !profileIconRef.current.contains(e.target)
      ) {
        setIsProfilePopoverOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside, true);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside, true);
    };
  }, [isProfilePopoverOpen]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isMobileProfileMenuOpen &&
        mobileProfileIconRef.current &&
        !mobileProfileIconRef.current.contains(e.target) &&
        mobileProfileListRef.current &&
        !mobileProfileListRef.current.contains(e.target)
      ) {
        setIsMobileProfileMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside, false);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside, false);
    };
  }, [isMobileProfileMenuOpen]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isMobileNavMenuOpen &&
        mobileNavMenuIconRef.current &&
        !mobileNavMenuIconRef.current.contains(e.target) &&
        mobileNavMenuListRef.current &&
        !mobileNavMenuListRef.current.contains(e.target)
      ) {
        setIsMobileNavMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside, true);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside, true);
    };
  }, [isMobileNavMenuOpen]);

  useEffect(() => {
    setIsMobileNavMenuOpen(false);
    setIsMobileProfileMenuOpen(false);
  }, [pathname]);

  return (
    <header className="page-header">
      <BackgroundEffects
        lightCircles={[{ className: "page-header__light-circle" }]}
      />
      {isTabletLandscape ? (
        <>
          <div className="page-header__nav-menu">
            <div className="page-header__logo-wrapper">
              <img
                src={ImageLogo}
                className="page-header__logo"
                onClick={() => navigate(Paths.HOME)}
                alt="footer-logo"
              />
            </div>
            <div
              className={cn("page-header__nav-item", {
                active: isAllEventsPage,
              })}
              onClick={() => navigate(Paths.NFT_TICKETS)}
            >
              NFT Tickets
            </div>
            <div
              className={cn("page-header__nav-item", { active: isMembership })}
              onClick={() => navigate(Paths.WHITE_LABEL)}
            >
              White Label
            </div>
            {/* {isConnected && (
          <div
            className={cn("page-header__nav-item", { active: isFavoritesPage })}
            onClick={() => navigate(Paths.FAVORITES)}
          >
            Favorites
          </div>
        )} */}
            <div
              className={cn("page-header__nav-item", {
                active: isHowItWorksPage,
              })}
              onClick={() => navigate(Paths.HOW_IT_WORKS)}
            >
              How it works
            </div>
            <div
              className={cn("page-header__nav-item", { active: isAboutPage })}
              onClick={() => navigate(Paths.ABOUT)}
            >
              About
            </div>
          </div>
          <div className="page-header__actions">
            {/* <Input
          value={search}
          onChange={onSearchChange}
          inputClassName="page-header__search-input"
          placeholder="Search"
          actionLeft={<IconSearch onClick={search.length > 0 ? onSearch : null} />}
          actionRight={search ? <IconClose onClick={clearSearch} /> : null}
          onKeyDown={onSearchKeyDown}
        /> */}
            {accessToken ? (
              <>
                {/* {!isConnected ? (
                  <div>
                    <Button
                      className="primary small"
                      onClick={goToWalletConnect}
                    >
                      Connect wallet
                    </Button>
                  </div>
                ) : null} */}
                <div
                  className="page-header__profile-container"
                  onClick={onProfileDropdownClick}
                  ref={profileIconRef}
                >
                  <div className="page-header__profile-image-container">
                    <IconProfile />
                  </div>
                  {isProfilePopoverOpen && (
                    <div className={cn("page-header__popover-wrapper")}>
                      <ul className="page-header__popover">
                        <li
                          className="page-header__popover-item"
                          onClick={goToMyTickets}
                        >
                          <IconTicketSmall />
                          My tickets
                        </li>
                        <li
                          className="page-header__popover-item"
                          onClick={goToMyAccount}
                        >
                          <IconProfileSmall />
                          My account
                        </li>
                        <li
                          className="page-header__popover-item"
                          onClick={openLogoutModal}
                        >
                          <IconLogoutSmall />
                          Logout
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="page-header__auth-btns-container">
                <div className="page-header__sign-in-btn" onClick={goToLogin}>
                  Sign in
                </div>
                <div
                  className="page-header__sign-up-btn"
                  onClick={goToRegister}
                >
                  Sign up
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="page-header__nav-menu">
            <div className="page-header__logo-wrapper">
              <img
                src={ImageLogo}
                className="page-header__logo"
                onClick={() => navigate(Paths.HOME)}
                alt="footer-logo"
              />
            </div>
          </div>
          <div className="page-header__actions">
            <div ref={mobileProfileIconRef} onClick={onMobileProfileMenuClick}>
              <div className="page-header__profile-image-container">
                <IconProfile />
              </div>
            </div>

            <div
              className="page-header__actions-menu"
              ref={mobileNavMenuIconRef}
              onClick={onMobileNavMenuClick}
            >
              <IconBurgerMenu />
            </div>
          </div>
        </>
      )}
      {isMobileProfileMenuOpen && (
        <div
          className={cn("page-header__mobile-menu-wrapper")}
          ref={mobileProfileListRef}
        >
          <ul className="page-header__mobile-menu">
            {accessToken ? (
              <>
                <li
                  className="page-header__mobile-menu-item"
                  onClick={goToMyAccount}
                >
                  My account
                </li>
                <li
                  className="page-header__mobile-menu-item"
                  onClick={goToMyTickets}
                >
                  My tickets
                </li>
                {/* {!isConnected ? (
                  <li className="page-header__mobile-menu-item" onClick={goToWalletConnect}>
                    Connect wallet
                  </li>
                ) : null} */}
                <li
                  className="page-header__mobile-menu-item"
                  onClick={openLogoutModal}
                >
                  Logout
                </li>
              </>
            ) : (
              <>
                <li
                  className="page-header__mobile-menu-item"
                  onClick={goToLogin}
                >
                  Sign in
                </li>
                <li
                  className="page-header__mobile-menu-item"
                  onClick={goToRegister}
                >
                  Sign up
                </li>
              </>
            )}
          </ul>
        </div>
      )}
      {isMobileNavMenuOpen && (
        <div
          className={cn("page-header__mobile-menu-wrapper", {
            connected: isConnected,
          })}
          ref={mobileNavMenuListRef}
        >
          <ul className="page-header__mobile-menu">
            <li
              className="page-header__mobile-menu-item"
              onClick={() => navigate(Paths.NFT_TICKETS)}
            >
              NFT Tickets
            </li>
            <li
              className="page-header__mobile-menu-item"
              onClick={() => navigate(Paths.NFT_MEMBERSHIP)}
            >
              NFT Membership
            </li>
            <li
              className="page-header__mobile-menu-item"
              onClick={() => navigate(Paths.HOW_IT_WORKS)}
            >
              How it works
            </li>
            <li
              className="page-header__mobile-menu-item"
              onClick={() => navigate(Paths.ABOUT)}
            >
              About us
            </li>
          </ul>
        </div>
      )}
      {isLogoutModalOpen && (
        <Modal
          onClose={closeLogoutModal}
          withCloseIcon
          actions={[
            <Button
              className="primary violet flex-grow white-border"
              onClick={closeLogoutModal}
            >
              Cancel
            </Button>,
            <Button className="primary pink flex-grow" onClick={logout}>
              {loading ? <ButtonLoader /> : "Log out"}
            </Button>,
          ]}
        >
          <h3>Would you like to log out from the TicketMeta platform?</h3>
        </Modal>
      )}
    </header>
  );

  // function onSearchChange(e) {
  //   setSearch(e.target.value);
  // }

  // function clearSearch() {
  //   setSearch("");
  // }

  // function onSearchKeyDown(e) {
  //   if (e.key === "Enter") {
  //     onSearch();
  //   }
  // }

  // function onSearch() {
  //   navigate(`${Paths.SEARCH_RESULTS}?search=${search}`);
  // }

  function openLogoutModal() {
    setIsLogoutModalOpen(true);
  }

  function closeLogoutModal() {
    setIsLogoutModalOpen(false);
  }

  function goToWalletConnect() {
    navigate(Paths.WALLET_CONNECT);
  }

  function goToLogin() {
    navigate(Paths.SIGN_IN);
  }

  function goToRegister() {
    navigate(Paths.Sign_UP);
  }

  function goToMyAccount() {
    navigate(Paths.MY_ACCOUNT);
  }

  function goToMyTickets() {
    navigate(Paths.MY_TICKETS);
  }

  function logout() {
    dispatch(getLogout()).then(() => {
      TokenService.removeTokens();
      navigate(Paths.HOME);
      closeLogoutModal();
      disconnect();
    });
  }

  function onProfileDropdownClick() {
    setIsProfilePopoverOpen((prev) => !prev);
  }

  function onMobileProfileMenuClick() {
    setIsMobileNavMenuOpen(false);
    setIsMobileProfileMenuOpen((prev) => !prev);
  }

  function onMobileNavMenuClick() {
    setIsMobileProfileMenuOpen(false);
    setIsMobileNavMenuOpen((prev) => !prev);
  }
};

export default Header;
