import { FC } from "react";
import cn from "classnames";
import { IconBackgroundStar } from "assets/icons";
import "./styles.scss";

interface LightCircle {
  className: string;
  size?: "medium" | "large";
}

interface Stars {
  className: string;
  size: "ex-small" | "small" | "medium" | "large" | "ex-large";
}

interface Ellipses {
  className: string;
  size?: "medium" | "large";
}

interface BackgroundEffectsProps {
  lightCircles?: LightCircle[];
  stars?: Stars[];
  ellipses?: Ellipses[];
}

const BackgroundEffects: FC<BackgroundEffectsProps> = ({ lightCircles, stars, ellipses }) => {
  return (
    <>
      {lightCircles?.length && (
        <div className="background-light-circle-wrapper">
          {lightCircles.map(({ size, className }, index) => (
            <div className={cn("background-light-circle", size, className)} key={`light-circle-${index}`} />
          ))}
        </div>
      )}
      {stars?.length &&
        stars.map(({ className, size }, index) => (
          <IconBackgroundStar className={cn("background-star", size, className)} key={`star-${index}`} />
        ))}
      {ellipses?.length &&
        ellipses.map(({ size, className }, index) => (
          <div className={cn("ellipse", size, className)} key={`ellipse-${index}`} />
        ))}
    </>
  );
};

export default BackgroundEffects;
