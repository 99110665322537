import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "api";

export const getProfile: any = createAsyncThunk("profile/get-profile", async (_, { rejectWithValue }) => {
  let url = "user/current";

  try { 
    const response = await API.get(url);
    const { data } = response;
    return data;
  } catch (e) {
    throw rejectWithValue(e);
  }
});

export const getWallet: any = createAsyncThunk("profile/get-wallet", async (_, { rejectWithValue }) => {
  let url = "user/wallet";

  try { 
    const response = await API.get(url);
    const { data } = response;
    return data;
  } catch (e) {
    throw rejectWithValue(e);
  }
});


export const getLogout: any = createAsyncThunk("profile/get-logout", async (_, { rejectWithValue }) => {
  let url = "auth/logout";

  try { 
    const response = await API.get(url);
    const { data } = response;
    return data;
  } catch (e) {
    throw rejectWithValue(e);
  }
});

export const updateProfile: any = createAsyncThunk("profile/update-profile", async (form, { rejectWithValue }) => {
  let url = "user/update-profile";

  try { 
    const response = await API.patch(url, form);
    const { data } = response;
    return data;
  } catch (e) {
    throw rejectWithValue(e);
  }
});
