import axios from "axios";
import TokenService from "services/token.service";

const { REACT_APP_API_SERVER } = process.env;

const API = axios.create({
  baseURL: REACT_APP_API_SERVER,
});

// Add access token to requests
API.interceptors.request.use(
  (config: any) => {
    const accessToken = TokenService.getLocalAccessToken();
    config.headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : "",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Catch errors and refresh token logic
API.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/auth/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        const refreshToken = TokenService.getLocalRefreshToken();

        try {
          const rs = await axios.get(`${REACT_APP_API_SERVER}auth/refresh-token`, {
            headers: {
              Authorization: refreshToken ? `Bearer ${refreshToken}` : "",
              "Content-Type": "application/json",
            },
          });

          const { accessToken } = rs.data;
          TokenService.updateLocalAccessToken(accessToken);

          return API(originalConfig);
        } catch (_error: any) {
          if (_error.response.status === 401) {
            return TokenService.removeTokens();
          }
          return Promise.reject(_error);
        }
      }
    }

    if (err.response.status === 403) {
      TokenService.removeTokens();
      window.location.reload();
    }

    return Promise.reject(err);
  }
);

export default API;
